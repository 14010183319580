<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-6">
							<h5 class="mb-4">
								<strong>Catálogo de Sucursales</strong>
							</h5>
						</div>
						<div class="col-md-6 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord()">Añadir</a-button>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload()" />
						</div>
					</div>

					<a-table :columns="columns" :dataSource="branchOfficesList" rowKey="id">
						<span slot="action" slot-scope="record">
							<a-button type="info" size="small" icon="edit" @click="onEditRecord(record)" />
						</span>
					</a-table>
				</div>
			</div>
		</a-spin>
		<insertOrUpdateBranchOffices :visible="modal.visible" :title="modal.title" :payload="modal.payload" @closeModal="onCloseModal" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import insertOrUpdateBranchOffices from '@/components/insertOrUpdateBranchOffices'

export default {
	name: 'branchOffices',
	components: {
		insertOrUpdateBranchOffices,
	},
	data() {
		return {
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					align: 'center',
				},
				{
					title: 'Sucursal',
					dataIndex: 'branch_office_name',
					align: 'center',
				},
				{
					title: 'Ubicacion',
					dataIndex: 'location',
				},
				{
					title: 'Responsable',
					dataIndex: 'fullName',
				},
			],
			modal: {
				visible: false,
				title: '',
				payload: {},
			},
		}
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('branchOffices', ['branchOfficesList']),
		spinnerLoaderLabel() {
			return this.$store.state.branchOffices.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.branchOffices.spinnerLoader
		},
		isUserAdmin() {
			return this.user.is_admin ? true : false
		},
	},
	beforeMount() {
		if (this.isUserAdmin) {
			this.columns.push({
				title: 'Acciones',
				scopedSlots: { customRender: 'action' },
				align: 'right',
			})
		}
	},
	mounted() {
		if (this.branchOfficesList.length == 0) {
			this.initModule()
		}
	},
	methods: {
		initModule() {
			this.$store.dispatch('branchOffices/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.modal = {
				visible: true,
				title: 'Añadir registro de una sucursal',
				payload: {},
			}
		},
		onEditRecord(record) {
			this.modal = {
				visible: true,
				title: 'Editar registro de una sucursal',
				payload: _.cloneDeep(record),
			}
		},
		onToggleRecord(id, status) {
			Swal.fire({
				title: 'Atención',
				text: `¿Desea ${status ? 'deshabilitar' : 'habilitar'} el registro?`,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Sí, continuar',
				cancelButtonText: 'Cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					if (status) {
						this.$store.dispatch('branchOffices/DISABLE', id)
					} else {
						this.$store.dispatch('branchOffices/ENABLE', id)
					}
				}
			})
		},
		onCloseModal() {
			this.modal.visible = false
		},
	},
}
</script>